jQuery(document).ready(function() {
  jQuery(window).keydown(function(event){
    if(event.keyCode == 13) {
      event.preventDefault();
      return false;
    }
  });
});

jQuery(document).ready(function(){

    // Mobile Menu

    jQuery('#mobile-menu').click(function(){

		jQuery('header').toggleClass('mobile-active');

		if(jQuery('header').hasClass('mobile-active')) {
			jQuery('body, html').css('overflow-y', 'hidden');
		} else {
			jQuery('body, html').css('overflow-y', 'auto');
		}
	});

    jQuery('.menu-item-has-children').click(function() {
        jQuery(this).toggleClass('active');
    });


    // Scroll Button

    var scrollButton = document.querySelector('.scroll')
    var scrollDistance = document.querySelector('#scroll-to')

    if(scrollButton && scrollDistance) {
        var height = scrollDistance.offsetHeight

        scrollButton.onclick = e => {
            e.preventDefault()

            window.scrollTo({
                left: 0,
                top: height + 30,
                behavior: 'smooth'
            })
        }
    }

    // Animate Scroll

    var animatedSections = jQuery('.animated-section');
    var windowHeight = window.innerHeight;

    if (animatedSections.length > 0) {

        animatedSections.each(function () {
            var section = jQuery(this);
            var offsetTop = section.offset().top;

            if (window.pageYOffset >= offsetTop - windowHeight / 1.8) {
                jQuery(section).addClass('active');
            }
        });

        window.addEventListener('scroll', function () {

            animatedSections.each(function () {
                var section = jQuery(this);
                var offsetTop = section.offset().top;

                if (window.pageYOffset >= offsetTop - windowHeight / 1.7) {
                    jQuery(section).addClass('active');
                }
            });
        });
    }


    // WooCommerce
        jQuery(document.body).on('updated_checkout', function(){
            jQuery('.checkout-qty').on('input', function(e) {
                if(!e.target.value || e.target.value.indexOf('-') > -1) {
                    e.preventDefault()
                    jQuery(this).val(jQuery(this).val().replace('-', ''))
                    return false
                }

                var key = jQuery(this).data('key')
                var qty = jQuery(this).val()

                jQuery.ajax({
                    type: 'POST',
                    dataType: 'json',
                    url: ajax_scripts_object.ajaxurl,
                    data: `action=update_cart_item_qty&key=${key}&qty=${qty}`,
                    success: function (result) {
                        jQuery('.woocommerce-Price-amount.amount').html(`$${result.cartTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`)
                    }
                });
            })
        })


        // Tabs
        var accordionTitles = [...document.querySelectorAll('.woocommerce-tabs .tabs.wc-tabs li')]
        accordionTitles.map(accordionTitle => {
            var accordionTarget = accordionTitle.querySelector('a').getAttribute('href')
            var accordionTab = document.querySelector(accordionTarget)

            accordionTitle.onclick = () => {
                accordionTitle.classList.toggle('open')
                accordionTab.classList.toggle('open')
            }
        })

})


jQuery(window).load(function(){
    jQuery('#product-slider, .slider').flickity({
        autoPlay: 8000,
        pauseAutoPlayOnHover: false,
        prevNextButtons: true,
        imagesLoaded: true,
        pageDots: true,
        setGallerySize: true,
        wrapAround: true,
    });

    jQuery('.slider').addClass('active');

    jQuery('#product-slider-controls').flickity({
        asNavFor: '#product-slider',
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        imagesLoaded: true,
        cellAlign: 'left'
    });
});
